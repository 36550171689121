@import '../../settings/settings.scss';

.PlayerNewSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  // height: 100vh;
  height: fit-content;

  padding-bottom: $header_height;
  background-color: $blue_0;

  .PlayerNewSection_Wrap {
    margin-top: $header_height;
    display: flex;
    max-width: $max-width;
    height: $height_player;
    width: 100%;

    @include player_back_Image;
    border-radius: 10px;
    //clip-path: inset(0% 0% round 10px);

    .PlayerNewSection_Wrap_Main {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      overflow: hidden;
      @include glass_1;
      height: 100%;
      width: 100%;
      border-radius: 10px;

      .PlayerNewSection_Wrap_Main_Video {
        width: 100%;
        height: $height_player;
        position: absolute;
        pointer-events: none;
        transition: opacity 0.4s 0.1s;

        &.hide {
          opacity: 0;
        }
        // width: 100vw;
        // height: 100vh;
      }

      .PlayerNewSection_Wrap_Main_BlurBox {
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;
        @include player_back_Image;
        opacity: 0;

        .PlayerNewSection_Wrap_Main_BlurBox_Glass {
          width: 100%;
          height: 100%;
          pointer-events: none;
          @include glass_1;
        }
      }

      .PlayerNewSection_Wrap_Main_Right {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        // margin-right: 10px;
        right: 10px;
        height: calc($height_player - 25px);
        width: $right_player_width;

        @include glass_2;

        .PlayerNewSection_Wrap_Main_Right_Wrap {
          // background-color: green;
          // padding-bottom: 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          // @include flexbox-row-gap(70px);
          width: 100%;
          height: 100%;
          padding-top: 115px;

          .PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer {
            // background-color: pink;
            pointer-events: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            z-index: 1;
            pointer-events: none;
            // padding: 100px 0;
            padding-bottom: 100px;
            margin-top: -60px;
            width: calc(100% - 35px);

            .PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer_Big {
              width: 100%;
              pointer-events: none;
              display: flex;
              flex-direction: column;
              align-items: center;

              font-size: 24px;
              font-weight: 600;
              //   width: 80%;

              .PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer_El {
                width: 100%;
                display: flex;
                justify-content: center;

                .dummy_text {
                  transition: flex 0.5s ease-in-out;
                }
              }
            }
            .PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer_Small {
              margin-top: 10px;
              pointer-events: none;
              font-size: 16px;
              font-weight: 400;
            }
          }

          .dummy {
            margin-bottom: 50px;
            width: 100px;
            transition: flex 0.5s ease-in-out, margin-bottom 0.5s ease-in-out;
          }

          .PlayerNewSection_Wrap_Main_Right_Wrap_Button {
            position: absolute;
            margin-top: 0px;
            display: flex;
            align-items: center;
            // gap: 10px;

            @include blue_btn;
            padding-left: 60px;
            padding-right: 60px;
            z-index: 2;

            &_Icon {
              width: 14px;
            }

            img {
              margin-left: 10px;
            }
          }

          .PlayerNewSection_Wrap_Main_Right_Wrap_Cards {
            display: flex;
            justify-content: center;
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 70%;

            .PlayerNewSection_Wrap_Main_Right_Wrap_Cards_Wrap {
              overflow-y: hidden;
              overflow-x: hidden;
              display: flex;
              flex-direction: column;
              width: calc(100% - 35px);
              height: calc(100% - 35px);
              // gap: 10px;
              @include flexbox-row-gap(10px);
              padding: 0px 8px;

              &::-webkit-scrollbar {
                background-color: transparent;
                width: 5px;
                max-height: 50%;
                height: 50%;
                border-radius: 333px;
              }

              &::-webkit-scrollbar-thumb {
                background: #ffffff;
                border-radius: 333px;
                width: 5px;
                height: 50%;
              }

              & {
                scrollbar-width: thin;
                scrollbar-color: #ffffff transparent;
              }

              &::-moz-scrollbar {
                width: 3px;
                max-height: 50%;
                height: 50%;
                border-radius: 333px;
              }

              &::-moz-scrollbar-thumb {
                background: #ffffff;
                border-radius: 333px;
                width: 3px;
                height: 50%;
              }

              .PlayerNewSection_Wrap_Main_Right_Wrap_Cards_Wrap_Helper {
                // opacity: 0;
                width: 100%;
                height: $timeBrick_height;
                transform-origin: bottom;

                clip-path: inset(0% 0% round 4px);
                background-color: rgba(255, 255, 255, 0);
                background-color: pink;

                // .PlayerNewSection_Wrap_Main_Right_Wrap_Cards_Wrap_Helper_Content {
                //   display: flex;
                //   flex-direction: column;
                //   gap: 10px;
                //   margin-left: $timeBrick_height + 20px;
                //   margin-top: 10px;
                //   margin-bottom: 25px;
                //   height: fit-content;

                //   transform-origin: top center;
                // }
              }
            }
          }
        }
      }
    }
    .testBlock_active {
      background-color: $blue_0 !important;
      //position: fixed;
      //top: 0;
      //left: 0;
      //right: 0;
      //bottom: 0;
      ////background-color: #000;
      //z-index: 9999;

      .PlayerNewSection_Wrap_Main_Video {
        width: 100vw;
        height: 100vh;
      }
    }
  }
}

.playerNew_active {
  .PlayerNewSection_Wrap {
    height: 100vh;

    .PlayerNewSection_Wrap_Main {
      .PlayerNewSection_Wrap_Main_Right {
        height: calc(100% - 25px);
        // width: calc()

        .PlayerNewSection_Wrap_Main_Right_Wrap {
          .PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer {
            margin-top: 50px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .PlayerNewSection {
    padding-bottom: $header_height / 2;
    .PlayerNewSection_Wrap {
      width: calc(100% - $margin_horizontal_1440 * 2);
      height: fit-content;

      .PlayerNewSection_Wrap_Main {
        height: 100%;
        .PlayerNewSection_Wrap_Main_Video {
          height: 100%;
        }

        .PlayerNewSection_Wrap_Main_Right {
          height: calc(100% - 25px);
        }

        .PlayerNewSection_Wrap_Main_BlurBox {
          .PlayerNewSection_Wrap_Main_BlurBox_Timeline {
            width: 100%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1340px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            padding-top: 5.9vw;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .PlayerNewSection {
    height: fit-content;
    margin-top: $header_height * 0.6;

    .PlayerNewSection_Wrap {
      align-items: center;
      flex-direction: column;
      background: none;
      width: 100%;
      clip-path: inset(0% 0% round 0px);
      background-image: none;

      .PlayerNewSection_Wrap_Main {
        background: none;
        flex-direction: column;
        height: 100%;
        padding-bottom: $header_height / 2;

        .PlayerNewSection_Wrap_Main_Video {
          pointer-events: all;
          height: 100%;
        }

        .PlayerNewSection_Wrap_Main_Adaptive {
          min-height: 230px;
          display: flex;
          align-items: center;
          justify-content: center;

          .PlayerNewSection_Wrap_Main_VideoStart {
            position: absolute;
          }

          .PlayerNewSection_Wrap_Main_BlurBox {
            background: $blue_0;
            .PlayerNewSection_Wrap_Main_BlurBox_Glass {
              background: none;
            }
          }
        }

        .PlayerNewSection_Wrap_Main_BlurBox_Timeline {
          width: 91vw;
        }

        .PlayerNewSection_Wrap_Main_Right {
          right: 0px;
          position: initial;
          width: 91vw;

          .PlayerNewSection_Wrap_Main_Right_Wrap {
            height: calc(100vw * 0.6);
            padding-top: 50px;

            .PlayerNewSection_Wrap_Main_Right_Wrap_Cards {
              overflow-y: hidden;

              .PlayerNewSection_Wrap_Main_Right_Wrap_Cards_Wrap {
                overflow: hidden !important;

                @include flexbox-row-gap(10px);
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      .PlayerNewSection_Wrap_Main {
        // width: calc(100% - $margin_horizontal_920 * 2);

        .PlayerNewSection_Wrap_Main_Right {
          // padding: 150px 0px;
          // height: 37vh;
          width: 91vw;

          .PlayerNewSection_Wrap_Main_Right_Wrap {
            // height: 90vh;
            height: calc(100vw * 0.65);
            .PlayerNewSection_Wrap_Main_Right_Wrap_Cards {
              .PlayerNewSection_Wrap_Main_Right_Wrap_Cards_Wrap {
                // gap: 10px;
                @include flexbox-row-gap(10px);
              }
            }

            .PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer {
              margin-top: -35px;

              .PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer_El {
                font-size: 20px;
              }

              .PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer_Small {
                margin-top: 7px;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 860px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            // height: 90vh;
            height: calc(100vw * 0.7);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            height: calc(100vw * 0.75);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            height: calc(100vw * 0.8);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            height: calc(100vw * 0.85);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            height: calc(100vw * 0.8);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 630px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            height: calc(100vw * 0.9);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            height: calc(100vw * 1.05);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      margin-top: 50px;
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          // padding: 150px 0px;
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            // height: 65vh;
            height: calc(100vw * 1.1);
            .PlayerNewSection_Wrap_Main_Right_Wrap_TextContainer {
              margin-top: -35px;
            }

            .PlayerNewSection_Wrap_Main_Right_Wrap_Button {
              padding-left: 45px;
              padding-right: 45px;
              z-index: 2;
              font-size: 13.5px;
              line-height: 125%;

              &_Icon {
                width: 11px;
              }
            }

            .PlayerNewSection_Wrap_Main_Right_Wrap_Cards {
              .PlayerNewSection_Wrap_Main_Right_Wrap_Cards_Wrap {
                gap: 0px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            height: calc(100vw * 1.15);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 430px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            height: calc(100vw * 1.25);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      margin-top: 50px;
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          // padding: 150px 0px;
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            // height: 60vh;
            height: calc(100vw * 1.4);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            height: calc(100vw * 1.5);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 330px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            height: calc(100vw * 1.65);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 290px) {
  .PlayerNewSection {
    .PlayerNewSection_Wrap {
      .PlayerNewSection_Wrap_Main {
        .PlayerNewSection_Wrap_Main_Right {
          .PlayerNewSection_Wrap_Main_Right_Wrap {
            height: calc(100vw * 1.8);
          }
        }
      }
    }
  }
}
