@import '../../settings/settings.scss';

.BgElips {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -100;

  .BgElips_Elips {
    position: relative;
    width: 0px;
    height: 30%;
    border-radius: 333px;
    // box-shadow: -30px 0px 300px 200px #c8e5ff;
  }

  .TechnologySection_Elips {
    // margin-top: 500px;
    // left: 300px;
    position: absolute;
    width: 0px;
    height: 5%;
    border-radius: 333px;
    z-index: -1;
    box-shadow: -30px 0px 250px 150px #cccccc;
  }
}
