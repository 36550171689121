$header_height: 110px;
$header_height_480: 90px;
$timeBrick_height: 70px;
$timeBrick_height_480: 60px;

// $right_player_width: 430px;
$right_player_width: 33%;
$height_player: 740px;

$max-width: 1320px;

$white: white;
$black: black;

$g_0: #edebe9;
$g_1: #757272;
$g_2: #6b6b6b;
$g_3: #262626;

$blue_0: #e5f7ff;
$blue_1: #1daef0;
$blue_2: #d1dde5;
$blue_3: #babfc6;

$margin_horizontal_1440: 70px;
$margin_horizontal_920: 40px;
$margin_horizontal_480: 30px;

@mixin blue_btn {
  background-color: $blue_1;
  color: $white;
  padding: 16px 38px;
  width: fit-content;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;

  transition: background-color 0.3s;

  &:hover {
    background-color: #20b7fd;
  }
}

@mixin glass_1 {
  transform: translate3d(0, 0, 0);
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px);
}

@mixin glass_2 {
  transform: translate3d(0, 0, 0);

  background: linear-gradient(
    110.28deg,
    rgba(255, 255, 255, 0.5) 0.2%,
    rgba(255, 255, 255, 0.4) 101.11%
  );
  border: 1.05972px solid #ffffff;
  box-shadow: -8.6129px 4.58153px 12.0036px rgba(0, 0, 0, 0.05),
    inset 6.29861px -4.23889px 15.29861px rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(100px);

  border-radius: 10px;
}

@mixin off_glass_2 {
  background: none;
  border: none;
  box-shadow: none;
  backdrop-filter: none;
}

@mixin glass_3 {
  transform: translate3d(0, 0, 0);
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
}

@mixin text_hover {
  transition: color 0.25s ease-in-out;
  &:hover {
    color: $g_3;
  }
}

@mixin player_back_Image {
  background-image: url('../../../public/img/player_back_1.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@mixin flexbox-column-gap($gap) {
  //display: grid;
  //column-gap: $gap;
  margin-right: -$gap;
  & > * {
    margin-right: $gap;
  }
}

@mixin flexbox-row-gap($gap) {
  //display: grid;
  //row-gap: $gap;
  margin-bottom: -$gap;

  & > * {
    margin-bottom: $gap;
  }
}
