@import '../../settings/settings.scss';

$left: 50%;
$right: 45%;

.HomeSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .HomeSection_Wrap {
    margin-top: $header_height;
    display: flex;
    justify-content: space-between;
    max-width: $max-width;
    width: 100%;

    .HomeSection_Wrap_Left {
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: $left;

      .HomeSection_Wrap_Left_Small {
        font-size: 20px;
        font-weight: 500;
        color: $g_2;
      }

      .HomeSection_Wrap_Left_Big {
        font-size: 48px;
        font-weight: 600;
      }
    }

    .HomeSection_Wrap_Right {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: $right;
      height: $right;
      // background-color: pink;

      .HomeSection_Wrap_Right_Img {
        position: relative;
        background-image: url('../../../public/img/floating/guy.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 700px;
      }

      .HomeSection_Wrap_Right_Img_El {
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      .HomeSection_Wrap_Right_Img_1 {
        position: absolute;
        //margin-top: -190px;
        //margin-left: -340px;
        left: 0;
        bottom: 50%;
        width: 220px;
        height: 220px;
        background-image: url('../../../public/img/floating/1.png');
        animation: float_1 6s ease-in-out infinite;

        @keyframes float_1 {
          0% {
            transform: translatey(0px);
          }
          50% {
            transform: translatey(35px);
          }
          100% {
            transform: translatey(0px);
          }
        }
      }
      .HomeSection_Wrap_Right_Img_2 {
        position: absolute;
        top: -10%;
        left: -10%;
        //margin-top: -440px;
        //margin-left: -350px;
        width: 350px;
        height: 350px;
        background-image: url('../../../public/img/floating/2.png');

        animation: float_2 4s ease-in-out infinite;

        @keyframes float_2 {
          0% {
            transform: translatey(0px);
          }
          50% {
            transform: translatey(-15px);
          }
          100% {
            transform: translatey(0px);
          }
        }
      }
      .HomeSection_Wrap_Right_Img_3 {
        position: absolute;
        top: 10%;
        right: 0;
        //margin-top: -360px;
        //margin-left: 300px;
        width: 180px;
        height: 180px;
        background-image: url('../../../public/img/floating/3.png');
        animation: float_3 5s ease-in-out infinite;

        @keyframes float_3 {
          0% {
            transform: translatey(0px);
          }
          50% {
            transform: translatey(-55px);
          }
          100% {
            transform: translatey(0px);
          }
        }
      }
      .HomeSection_Wrap_Right_Img_4 {
        position: absolute;
        bottom: 40%;
        right: -10%;
        //margin-top: -50px;
        //margin-left: 420px;
        width: 200px;
        height: 200px;
        background-image: url('../../../public/img/floating/4.png');

        animation: float_4 3s ease-in-out infinite;

        @keyframes float_4 {
          0% {
            transform: translatey(0px);
          }
          50% {
            transform: translatey(18px);
          }
          100% {
            transform: translatey(0px);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .HomeSection {
    .HomeSection_Wrap {
      margin: 0px $margin_horizontal_1440;

      .HomeSection_Wrap_Right {
        .HomeSection_Wrap_Right_Img {
          height: 40vw;
        }

        .HomeSection_Wrap_Right_Img_1 {
          //margin-top: -190px;
          //margin-left: -30vw;
          left: 8%;
          width: 30%;
          height: 30%;
        }
        .HomeSection_Wrap_Right_Img_2 {
          //margin-top: -410px;
          //margin-left: -30vw;
          top: -10%;
          left: 0;
          width: 50%;
          height: 50%;
        }
        .HomeSection_Wrap_Right_Img_3 {
          //margin-top: -350px;
          //margin-left: 25vw;
          right: 10%;
          width: 20%;
          height: 20%;
        }
        .HomeSection_Wrap_Right_Img_4 {
          right: 0;
          //margin-top: -50px;
          //margin-left: 35vw;
          width: 30%;
          height: 30%;
        }
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .HomeSection {
    .HomeSection_Wrap {
      .HomeSection_Wrap_Left {
        .HomeSection_Wrap_Left_Small {
          font-size: 12px;
        }

        .HomeSection_Wrap_Left_Big {
          font-size: 40px;
        }
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .HomeSection {
    height: fit-content;

    .HomeSection_Wrap {
      height: 100%;
      margin: 0px $margin_horizontal_920;
      margin-top: ($header_height + $header_height * 0.6);
      flex-direction: column;

      .HomeSection_Wrap_Left {
        width: 100%;

        .HomeSection_Wrap_Left_Small {
          font-size: 12px;
        }

        .HomeSection_Wrap_Left_Big {
          font-size: 32px;
        }
      }

      .HomeSection_Wrap_Right {
        width: 100%;

        .HomeSection_Wrap_Right_Img {
          height: 100vw;
        }

        .HomeSection_Wrap_Right_Img_1 {
          //margin-top: -190px;
          //margin-left: -50vw;
          width: 30%;
          height: 30%;
        }
        .HomeSection_Wrap_Right_Img_2 {
          //margin-top: -440px;
          //margin-left: -45vw;
          width: 45%;
          height: 45%;
        }
        .HomeSection_Wrap_Right_Img_3 {
          //margin-top: -360px;
          //margin-left: 50vw;
          width: 20%;
          height: 20%;
        }
        .HomeSection_Wrap_Right_Img_4 {
          margin-top: -50px;
          margin-left: 60vw;
          width: 25%;
          height: 25%;
        }
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .HomeSection {
    .HomeSection_Wrap {
      .HomeSection_Wrap_Right {
        .HomeSection_Wrap_Right_Img_1 {
          //margin-top: -120px;
          //margin-left: -50vw;
          width: 30%;
          height: 30%;
        }
        .HomeSection_Wrap_Right_Img_2 {
          //margin-top: -340px;
          //margin-left: -45vw;
          width: 45%;
          height: 45%;
        }
        .HomeSection_Wrap_Right_Img_3 {
          //margin-top: -290px;
          //margin-left: 50vw;
          width: 20%;
          height: 20%;
        }
        .HomeSection_Wrap_Right_Img_4 {
          margin-top: -50px;
          margin-left: 60vw;
          width: 25%;
          height: 25%;
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .HomeSection {
    height: fit-content;

    .HomeSection_Wrap {
      margin: 0px $margin_horizontal_480;
      margin-top: ($header_height_480 + $header_height_480 * 0.6);
      flex-direction: column;

      .HomeSection_Wrap_Left {
        width: 100%;

        .HomeSection_Wrap_Left_Small {
          font-size: 10px;
        }

        .HomeSection_Wrap_Left_Big {
          font-size: 24px;
        }
      }

      .HomeSection_Wrap_Right {
        width: 100%;

        .HomeSection_Wrap_Right_Img {
          height: 100vw;
        }

        .HomeSection_Wrap_Right_Img_1 {
          //margin-top: -100px;
          //margin-left: -50vw;
          width: 30%;
          height: 30%;
        }
        .HomeSection_Wrap_Right_Img_2 {
          //margin-top: -240px;
          //margin-left: -45vw;
          width: 45%;
          height: 45%;
        }
        .HomeSection_Wrap_Right_Img_3 {
          //margin-top: -220px;
          //margin-left: 50vw;
          width: 20%;
          height: 20%;
        }
        .HomeSection_Wrap_Right_Img_4 {
          margin-top: -50px;
          margin-left: 60vw;
          width: 25%;
          height: 25%;
        }
      }
    }
  }
}
