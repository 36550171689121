@import '../../settings/settings.scss';

.TechnologySection {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: calc(100% + $header_height);
  clip-path: inset(0% 0% round 0);

  .TechnologySection_Container {
    width: 100%;
    height: 1000px;
    display: flex;
    justify-content: center;

    overflow: hidden;

    .TechnologySection_Wrap_SeeMore {
      display: flex;
      align-items: flex-end;
      position: absolute;
      z-index: 50;
      pointer-events: none;
      width: 100vw;
      height: 1000px;

      .TechnologySection_Wrap_SeeMore_Wrap {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        width: 100%;
        // height: calc(100% - 700px);
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 70%,
          #ffffff 85%
        );

        .TechnologySection_Wrap_SeeMore_Wrap_Button {
          // margin-top: 300px;

          height: fit-content;
          // position: relative;
          // bottom: 70vh;
          // margin-bottom: 0vw;
          border: 3px $blue_1 solid;
          font-size: 18px;
          font-weight: 600;
          padding: 16px 38px;
          border-radius: 5px;
          color: $blue_1;
          cursor: pointer;
          pointer-events: all;

          transition: background-color 0.4s ease-in-out, color 0.3s ease-in-out;

          &:hover {
            background-color: #20b7fd;
            color: $white;
          }
        }
      }
    }
  }

  .TechnologySection_Wrap {
    margin-top: $header_height / 2;
    display: flex;
    max-width: $max-width;
    align-items: center;
    flex-direction: column;
    width: 100%;
    // gap: 50px;
    // @include flexbox-row-gap(50px);

    .TechnologySection_Wrap_Title {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // gap: 10px;
      // @include flexbox-row-gap(70px);
      text-align: center;

      .TechnologySection_Wrap_Title_Big {
        font-size: 46px;
        font-weight: 600;
        color: $g_3;
      }

      .TechnologySection_Wrap_Title_Small {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        color: $g_2;
        width: 82%;
      }
    }
  }

  .TechnologySection_Wrap_Main {
    // margin-top: 50px;
    padding-top: 50px;
    z-index: inherit;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // gap: 50px;

    .CardSection {
      margin: 10px;
      width: 315px;
      height: 330px;
      transition: all 0.4s ease-in-out;
      position: relative;
      background: linear-gradient(
        111.41deg,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0.1) 100%
      );
      box-shadow: 0px 47px 124px rgba(0, 0, 0, 0.08);

      border: 3px solid #ffffff;
      border-radius: 12px;
      will-change: transform;
      -webkit-font-smoothing: antialiased;

      &:hover {
        transition: all 0.4s ease-in-out, backdrop-filter 0s ease-in-out;
        transform: scale(1.24) translateZ(0);
        box-shadow: 0px 0px 35px 35px hsla(195, 8%, 63%, 0.15);
        z-index: 20;
        background: linear-gradient(
          111.41deg,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(255, 255, 255, 0.1) 100%
        );
        backdrop-filter: blur(62.1748px);

        &:before {
          opacity: 1;
        }
      }

      .CardSection_Container {
        $anim_duration: 0.4s;

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        border-radius: 10px;

        width: 100%;
        height: 100%;
        transform-origin: center center;
      }

      .CardSection_Container_Wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 88%;
        height: 80%;
        // background-color: pink;

        .CardSection_Container_Wrap_Top {
          display: flex;
          align-items: center;
          // gap: 21px;
          @include flexbox-column-gap(21px);

          .CardSection_Container_Wrap_Top_Logo {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $white;
            min-width: 50px;
            min-height: 50px;
            border-radius: 333px;

            transition: width 0.2s ease-in-out, height 0.35s ease-in-out;

            .CardSection_Container_Wrap_Top_Logo_Icon {
              max-width: 30px;
              max-height: 30px;
            }
          }

          .CardSection_Container_Wrap_Top_Title {
            font-size: 18px;
            font-weight: 600;
            color: $g_3;

            transition: font-size 0.3s ease-in-out;
          }
        }

        .CardSection_Container_Wrap_Content {
          font-size: 16px;
          font-weight: 400;
          color: $g_2;

          transition: font-size 0.45s ease-in-out;
        }

        .CardSection_Container_Wrap_LearnMore {
          text-decoration: none;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
          color: $g_1;
          display: flex;
          // gap: 5px;
          // @include flexbox-column-gap(5px);
          align-items: center;

          transition: font-size 0.35s ease-in-out;

          @include text_hover;

          img {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .TechnologySection {
    .TechnologySection_Wrap {
      .TechnologySection_Wrap_Main {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .TechnologySection {
    height: fit-content;

    .TechnologySection_Container {
      height: fit-content;

      .TechnologySection_Wrap {
        margin-top: $header_height / 2;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;

        .TechnologySection_Wrap_Title {
          flex-direction: column;
          align-items: flex-start;
          text-align: start;
          margin-left: $margin_horizontal_1440;

          .TechnologySection_Wrap_Title_Big {
          }

          .TechnologySection_Wrap_Title_Small {
            width: 82%;
          }
        }

        .TechnologySection_Wrap_Main_Scroll {
          display: flex;
          align-items: center;
          width: 100vw;
          height: 400px;
          overflow-y: hidden;
          overflow-x: scroll;
          scrollbar-width: none;
          -ms-overflow-style: none;

          &::-webkit-scrollbar {
            display: none;
            width: 0;
            height: 0;
          }

          &::-webkit-scrollbar-track {
            display: none;
          }

          &::-webkit-scrollbar-thumb {
            background: transparent;
            width: 0;
            height: 0;
          }

          &::-moz-scrollbar {
            width: 0;
          }

          &::-moz-scrollbar-thumb {
            width: 0;
            height: 0;
          }

          .TechnologySection_Wrap_Main {
            margin-left: $margin_horizontal_1440;
            margin-right: $margin_horizontal_1440;
            display: flex;
            width: fit-content;

            .CardSection {
              backdrop-filter: none;
              pointer-events: none;
              background: linear-gradient(
                111.41deg,
                rgba(255, 255, 255, 0.8) 0%,
                rgba(255, 255, 255, 0.8) 100%
              );
              box-shadow: none;

              &:hover {
                background: linear-gradient(
                  111.41deg,
                  rgba(255, 255, 255, 0.8) 0%,
                  rgba(255, 255, 255, 0.8) 100%
                );
                transition: all 0.4s ease-in-out;
                transform: scale(1);
                box-shadow: none;
                z-index: 0;

                backdrop-filter: blur(0px);
              }

              .CardSection_Container {
                .CardSection_Container_Wrap {
                  .CardSection_Container_Wrap_LearnMore {
                    pointer-events: all;
                  }
                }
              }
            }
          }
        }

        .TechnologySection_Wrap_Main_Dots {
          margin-top: 40px;
          width: 100vw;
          height: fit-content;

          .TechnologySection_Wrap_Main_Dots_Wrap {
            $dot_size: 10px;
            $dot_gap: 10px;

            display: flex;
            //gap: $dot_gap;
            @include flexbox-column-gap($dot_gap);
            width: 80%;
            margin-left: $margin_horizontal_1440;
            margin-right: $margin_horizontal_1440;

            .TechnologySection_Wrap_Main_Dots_Wrap_El {
              width: $dot_size;
              height: $dot_size;
              background-color: $blue_3;
              border-radius: 333px;
            }
            .TechnologySection_Wrap_Main_Dots_Wrap_WormWrap {
              display: flex;
              position: absolute;
              width: fit-content;
              height: fit-content;

              .TechnologySection_Wrap_Main_Dots_Wrap_Worm {
                position: absolute;

                width: $dot_size;
                height: $dot_size;
                background-color: $blue_1;
                border-radius: 333px;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                backface-visibility: hidden;
                will-change: transform;
              }
            }
          }
        }

        .TechnologySection_Wrap_SeeMore {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .TechnologySection {
    .TechnologySection_Container {
      height: fit-content;
      .TechnologySection_Wrap {
        height: fit-content;
        .TechnologySection_Wrap_Title {
          margin-left: $margin_horizontal_920;
        }

        .TechnologySection_Wrap_Main_Scroll {
          .TechnologySection_Wrap_Main {
            margin-left: $margin_horizontal_920;
            margin-right: $margin_horizontal_920;
          }
        }

        .TechnologySection_Wrap_Main_Dots {
          .TechnologySection_Wrap_Main_Dots_Wrap {
            margin-left: $margin_horizontal_920;
            margin-right: $margin_horizontal_920;
          }
        }

        .TechnologySection_Wrap_SeeMore {
          display: none;
        }
      }
    }
  }
}
