@import '../../settings/settings.scss';

.FrooterBannerSection {
  // opacity: 0.4;
  display: flex;
  justify-content: center;
  width: 100vw;

  // background: linear-gradient(
  //   180deg,
  //   rgb(255, 255, 255) 20%,
  //   rgba(255, 255, 255, 0) 90%
  // );
  background-color: white;
  z-index: 100;

  .FrooterBannerSection_Wrap {
    margin-top: $header_height / 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    max-width: $max-width;
    width: 100%;
    height: fit-content;

    .FrooterBannerSection_Wrap_Main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;

      background-color: transparent;
      z-index: 1;

      .FrooterBannerSection_Wrap_Main_Left {
        display: flex;
        flex-direction: column;
        // gap: 46px;
        @include flexbox-row-gap(46px);

        .FrooterBannerSection_Wrap_Main_Left_Text {
          font-size: 46px;
          font-weight: 600;
        }

        .FrooterBannerSection_Wrap_Main_Left_Button {
          text-decoration: none;
          @include blue_btn;
          font-size: 18px;
          font-weight: 600;
        }
      }

      .FrooterBannerSection_Wrap_Main_Right {
        width: 100%;
        .FrooterBannerSection_Wrap_Main_Right_Image {
          width: 80%;
        }
      }
      .FrooterBannerSection_Wrap_Main_RightImageWrap {
        width: 100%;
        .FrooterBannerSection_Wrap_Main_RightImage {
          width: 100%;
        }
      }
    }

    .FrooterBannerSection_Wrap_BlueRect {
      border-radius: 10px;
      background-color: $blue_0;
      max-width: $max-width;
      width: 100%;
      max-height: 475px;
      height: 80%;
      position: absolute;
    }
  }
}

@media screen and (max-width: 1440px) {
  .FrooterBannerSection {
    .FrooterBannerSection_Wrap {
      width: calc(100% - $margin_horizontal_1440 * 2);
      .FrooterBannerSection_Wrap_Main {
        .FrooterBannerSection_Wrap_Main_Left {
          .FrooterBannerSection_Wrap_Main_Left_Text {
            font-size: 36px;
          }
        }
      }

      .FrooterBannerSection_Wrap_BlueRect {
        width: calc(100% - $margin_horizontal_1440 * 2);
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .FrooterBannerSection {
    background: none;
    .FrooterBannerSection_Wrap {
      margin-top: $header_height * 0.9;
      height: 100%;

      .FrooterBannerSection_Wrap_Main {
        flex-direction: column-reverse;
        justify-content: flex-end;
        // gap: 12px;
        @include flexbox-row-gap(12px);
        height: fit-content;
        width: 100%;
        border-radius: 10px;
        padding-bottom: 50px;
        background-color: $blue_0;

        .FrooterBannerSection_Wrap_Main_Left {
          // gap: 20px;
          @include flexbox-row-gap(20px);
          width: 80%;
          .FrooterBannerSection_Wrap_Main_Left_Text {
            font-size: 46px;
          }
        }

        .FrooterBannerSection_Wrap_Main_RightImage {
          position: relative;
          top: -70px;
        }
      }

      .FrooterBannerSection_Wrap_BlueRect {
        display: none;
        max-height: 800px;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .FrooterBannerSection {
    .FrooterBannerSection_Wrap {
      width: calc(100% - $margin_horizontal_920 * 2);

      .FrooterBannerSection_Wrap_Main {
        .FrooterBannerSection_Wrap_Main_Left {
          .FrooterBannerSection_Wrap_Main_Left_Text {
            font-size: 38px;
          }
        }
      }

      .FrooterBannerSection_Wrap_BlueRect {
        width: calc(100% - $margin_horizontal_920 * 2);
      }
    }
  }
}
@media screen and (max-width: 620px) {
  .FrooterBannerSection {
    .FrooterBannerSection_Wrap {
      .FrooterBannerSection_Wrap_Main {
        .FrooterBannerSection_Wrap_Main_RightImage {
          position: relative;
          top: -50px;
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .FrooterBannerSection {
    .FrooterBannerSection_Wrap {
      width: calc(100% - $margin_horizontal_480 * 2);
      .FrooterBannerSection_Wrap_Main {
        .FrooterBannerSection_Wrap_Main_Left {
          .FrooterBannerSection_Wrap_Main_Left_Text {
            font-size: 32px;
          }
        }
      }

      .FrooterBannerSection_Wrap_BlueRect {
        width: calc(100% - $margin_horizontal_480 * 2);
      }
    }
  }
}
