body,
html {
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  /* font-family: 'Segoe UI', sans-serif; */
}

body {
  overflow-y: hidden;
}
