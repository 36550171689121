@font-face {
  font-family: 'Segoe-UI';
  src: url('../../public/fonts/segoeui.ttf');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Segoe-UI';
  src: url('../../public/fonts/seguisb.ttf');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'Segoe-UI';
  src: url('../../public/fonts/segoeuib.ttf');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Segoe-UI';
  src: url('../../public/fonts/seguibl.ttf');
  font-style: normal;
  font-weight: 800;
}

body {
  font-family: 'Segoe-UI', sans-serif;
}
