@import '../../settings/settings.scss';

.TimeBrickSection {
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: $timeBrick_height;
  transform-origin: bottom;
  border-radius: 7px;
  clip-path: inset(0% 0% round 7px);
  background-color: rgba(255, 255, 255, 0);
  z-index: 1;

  // transition: margin-top 0.5s ease-in-out;

  .TimeBrickSection_Wrap {
    // background-color: pink;
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: $timeBrick_height;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(23px);
    border-bottom-left-radius: 7px;

    cursor: pointer;

    .TimeBrickSection_Wrap_Left {
      pointer-events: all;
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
      width: 100%;

      .TimeBrickSection_Wrap_Play {
        cursor: pointer;
        display: flex;
        justify-content: center;
        background-color: $blue_2;
        min-width: $timeBrick_height;
        width: $timeBrick_height;
        height: $timeBrick_height;

        border-bottom-left-radius: 7px;
        transition: background 1s ease-in-out;

        .TimeBrickSection_Wrap_Play_Icon {
          scale: 0.25;
        }
      }

      .TimeBrickSection_Wrap_Text {
        display: flex;
        flex-direction: column;
        // gap: 3px;
        margin-left: 20px;
        @include flexbox-column-gap(3px);

        .TimeBrickSection_Wrap_Text_Title {
          font-size: 19px;
          font-weight: 500;
          color: $g_2;
        }
        .TimeBrickSection_Wrap_Text_SubTitle {
          font-size: 12px;
          font-weight: 400;
          color: $g_2;
        }
      }
    }

    .TimeBrickSection_Wrap_Arrow {
      pointer-events: all;
      display: flex;
      align-items: center;
      justify-content: center;

      transition: all 0.3s ease-in-out;

      &.active {
        transform: rotate(180deg);
        transition: all 0.3s ease-in-out;
      }

      .TimeBrickSection_Wrap_Arrow_Icon {
        margin: 20px;
        scale: 0.4;
        transform: rotate(180deg);
      }
    }
  }

  .TimeBrickSection_Content {
    white-space: pre-line;
    width: 88%;
    // display: none;
    flex-direction: column;
    // gap: 10px;
    @include flexbox-column-gap(10px);
    margin-left: $timeBrick_height + 20px;
    margin-top: 10px;
    margin-bottom: 25px;
    height: fit-content;
    transform-origin: top center;

    .TimeBrickSection_Content_Text {
      line-height: 1.25;
      width: 75%;
      font-size: 12px;
      font-weight: 400;
      color: $g_2;
    }

    .TimeBrickSection_Content_Technologies {
      margin-top: 5px;
      width: 90%;
      display: flex;
      flex-direction: column;
      // gap: 10px;
      @include flexbox-row-gap(10px);

      .TimeBrickSection_Content_Technologies_Title {
        font-size: 11px;
        font-weight: 400;
        color: $g_2;
      }

      .TimeBrickSection_Content_Technologies_List {
        width: 90%;
        display: inline-flex;
        flex-wrap: wrap;
        // gap: 7px;
        @include flexbox-row-gap(7px);
        @include flexbox-column-gap(7px);

        .TimeBrickSection_Content_Technologies_List_El {
          background-color: $g_0;
          padding: 3px 9px;
          border-radius: 3px;
          color: $black;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
  }
}

.timeBrick_active {
  .TimeBrickSection_Content {
    .TimeBrickSection_Content_Text {
      width: 90%;
    }
  }
}

.TimeBrickSection_Back {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(93, 185, 250, 0);
  opacity: 0;
  margin-left: -4px;
}

@media screen and (max-width: 1440px) {
  .TimeBrickSection {
    .TimeBrickSection_Wrap {
      .TimeBrickSection_Wrap_Left {
        .TimeBrickSection_Wrap_Text {
          .TimeBrickSection_Wrap_Text_Title {
            font-size: 1.3vw;
            font-size: 700;
          }

          .TimeBrickSection_Wrap_Text_SubTitle {
            font-size: 1vw;
          }
        }
      }
    }

    .TimeBrickSection_Content {
      width: 80%;
    }
  }
}
@media screen and (max-width: 1240px) {
  .TimeBrickSection {
    .TimeBrickSection_Wrap {
      .TimeBrickSection_Wrap_Left {
        .TimeBrickSection_Wrap_Text {
          .TimeBrickSection_Wrap_Text_Title {
            font-size: 1.1vw;
            font-size: 700;
          }
          .TimeBrickSection_Wrap_Text_SubTitle {
            font-size: 0.9vw;
          }
        }
      }
    }
  }
}
// @media screen and (max-width: 1140px) {
//   .TimeBrickSection {
//     .TimeBrickSection_Wrap {
//       .TimeBrickSection_Wrap_Left {
//         .TimeBrickSection_Wrap_Text {
//           .TimeBrickSection_Wrap_Text_Title {
//             font-size: 1.1vw;
//             font-size: 600;
//           }
//         }
//       }
//     }
//   }
// }
@media screen and (max-width: 1080px) {
  .TimeBrickSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: $timeBrick_height_480;

    .TimeBrickSection_Wrap {
      height: $header_height_480;

      .TimeBrickSection_Wrap_Left {
        .TimeBrickSection_Wrap_Text {
          .TimeBrickSection_Wrap_Text_Title {
            font-size: 19px;
          }
          .TimeBrickSection_Wrap_Text_SubTitle {
            font-size: 12px;
          }
        }
      }
    }

    .TimeBrickSection_Content {
      overflow-y: auto;
      width: 90%;
      margin-left: 0px;
      height: 100vh;

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 0px;
      }

      &::-webkit-scrollbar-thumb {
        width: 0px;
      }

      &::-moz-scrollbar {
        width: 0px;
      }

      &::-moz-scrollbar-thumb {
        width: 0px;
      }

      .TimeBrickSection_Content_Text {
        width: 85%;
        font-size: 12px;
        font-weight: 400;
        color: $g_2;
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .TimeBrickSection {
    height: $header_height_480;
    margin-bottom: 0px !important;
    .TimeBrickSection_Wrap {
      height: $timeBrick_height_480;

      .TimeBrickSection_Wrap_Left {
        .TimeBrickSection_Wrap_Play {
          border-bottom-left-radius: 6px;
          border-top-left-radius: 6px;
          min-width: $timeBrick_height_480;
          width: $timeBrick_height_480;
          height: $timeBrick_height_480;
        }

        .TimeBrickSection_Wrap_Text {
          .TimeBrickSection_Wrap_Text_Title {
            font-size: 2.9vw;
            font-weight: 600;
          }
          .TimeBrickSection_Wrap_Text_SubTitle {
            font-size: 2.3vw;
          }
        }
      }
    }

    .TimeBrickSection_Wrap_Arrow {
      width: 30px;
      margin-right: 10px;
      .TimeBrickSection_Wrap_Arrow_Icon {
        margin: 10px;
        scale: 0.8;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .TimeBrickSection {
    // height: 50px;

    .TimeBrickSection_Wrap {
      // height: $timeBrick_height_480;

      .TimeBrickSection_Wrap_Left {
        .TimeBrickSection_Wrap_Play {
          min-width: $timeBrick_height_480;
          width: $timeBrick_height_480;
          height: $timeBrick_height_480;
        }

        .TimeBrickSection_Wrap_Text {
          margin-left: 10px;
          .TimeBrickSection_Wrap_Text_Title {
            font-size: 3.6vw;
            // font-weight: 600;
          }
          .TimeBrickSection_Wrap_Text_SubTitle {
            font-size: 2.2vw;
          }
        }
      }

      .PlayerSection_Wrap_Right {
        .PlayerSection_Wrap_Right_Main {
          .PlayerSection_Wrap_Right_Main_TimeBiriks {
            .PlayerSection_Wrap_Right_Main_TimeBiriks_Scroll {
              row-gap: 0px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 350px) {
  .TimeBrickSection {
    .TimeBrickSection_Wrap {
      .TimeBrickSection_Wrap_Left {
        .TimeBrickSection_Wrap_Text {
          .TimeBrickSection_Wrap_Text_Title {
            font-size: 3vw;
            // font-weight: 600;
          }
          .TimeBrickSection_Wrap_Text_SubTitle {
            font-size: 2.3vw;
          }
        }
      }
    }
  }
}
