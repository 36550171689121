@import '../../settings/settings.scss';

.HeaderSection {
  display: flex;
  justify-content: center;
  width: 100vw;
  position: fixed;
  z-index: 100;
  top: 0px;
  @include glass_1;

  .HeaderSection_Wrap {
    height: $header_height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: $max-width;
    width: 100%;

    .HeaderSection_Wrap_Logo {
      display: flex;
      align-items: center;

      .HeaderSection_Wrap_Logo_Img {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

         height: 25px;
      }

  
      .ImgSeparator{
        margin: 0px 10px;
        // height: 25px;
        width: 15px;
        background-image: url('../../../public/img/separator_logo.svg');
      }

      .Img3{
        margin-top: -8px;
        scale: 1.1;
      }
    }

    .HeaderSection_Wrap_Center {
      display: flex;
      justify-content: center;
      @include flexbox-column-gap(63px);

      .HeaderSection_Wrap_Center_El {
        display: flex;
        width: fit-content;
        color: $g_1;
        font-size: 16px;
        font-weight: 300;
        text-decoration: none;
        cursor: pointer;

        @include text_hover;
      }
    }

    .HeaderSection_Wrap_Center_MenuButton {
      display: flex;
      align-items: center;

      .HeaderSection_Wrap_Center_MenuButton_Wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        cursor: pointer;
      }
    }
  }
}

.HeaderSection_Menu {
  display: flex;
  justify-content: center;
  // align-items: center;
  z-index: 90;
  position: fixed;
  width: 100vw;
  height: 100vh;
  @include glass_3;

  .HeaderSection_Menu_Wrap {
    display: flex;
    flex-direction: column;
    margin: calc($header_height + $header_height / 2) $margin_horizontal_920 0 ;
    //margin-top: calc($header_height + $header_height / 2);
    height: fit-content;
    width: 100%;
    //gap: 33px;
    @include flexbox-row-gap(33px);

    .HeaderSection_Menu_Wrap_Title {
      font-size: 42px;
      font-weight: 700;
    }

    .HeaderSection_Menu_Wrap_Links {
      display: flex;
      flex-direction: column;
      //gap: 33px;
      @include flexbox-row-gap(33px);

      a {
        text-decoration: none;
        font-size: 29px;
        font-weight: 600;
        color: $g_1;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .HeaderSection {
    .HeaderSection_Wrap {
      margin: 0px $margin_horizontal_1440;
    }
  }
}

@media screen and (max-width: 1080px) and (max-height: 450px) {
  .HeaderSection {
    .HeaderSection_Wrap {
      height: 70px;
      //.HeaderSection_Wrap_Center {
      //  gap: 30px;
      //}
    }
  }
}

@media screen and (max-width: 1080px) {
  .HeaderSection {
    .HeaderSection_Wrap {
      .HeaderSection_Wrap_Center {
        // gap: 30px;
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .HeaderSection {
    .HeaderSection_Wrap {
      margin: 0px $margin_horizontal_920;
    }
  }
}
@media screen and (max-width: 480px) {
  .HeaderSection {
    position: fixed;
    z-index: 100;

    .HeaderSection_Wrap {
      height: $header_height_480;
      margin: 0px $margin_horizontal_480;

      .HeaderSection_Wrap_Logo {
        .HeaderSection_Wrap_Logo_Img {
          max-width: 100px;
          height: 15px;
        }

       .ImgSeparator{
        margin: 0px 5px;
       }

       .Img3{
        margin-top: -4.6px;
        scale: 1.1;
      }
      }
    }
  }

  .HeaderSection_Menu {
    .HeaderSection_Menu_Wrap {
      margin: 0px $margin_horizontal_480;
      margin-top: calc($header_height_480 + $header_height_480 / 2);

      .HeaderSection_Menu_Wrap_Title {
        font-size: 32px;
      }

      .HeaderSection_Menu_Wrap_Links {
        a {
          font-size: 19px;
        }
      }
    }
  }
}
