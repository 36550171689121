@import '../../../settings/settings.scss';

.TimelineBarSection {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: 0;
  position: absolute;
  // width: calc(100% - $right_player_width - 20px);
  width: calc(100% - $right_player_width - 20px);

  z-index: 100;
  opacity: 0;

  .TimelineBarSection_Wrap {
    margin: 15px 0px;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    // gap: 20px;
    // @include flexbox-column-gap(20px);

    @include glass_1;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 7px;

    .TimelineBarSection_Section_PrevPauseNext {
    
      display: flex;
      align-items: center;
      // gap: 20px;
      @include flexbox-column-gap(20px);
      margin-right: 10px;

      img {
        cursor: pointer;
      }

      .TimelineBarSection_Section_PrevPauseNext_Pause {
        max-width: 15px;
        min-width: 15px;
      }
    }

    .TimelineBarSection_Section_TimeLine {
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      // width: 50%;

      input {
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        cursor: pointer;
        width: 100%;

        z-index: 1;

        &::-webkit-slider-runnable-track {
          background: transparent;
        }

        /******** Firefox ********/
        &::-moz-range-track {
          background: transparent;
        }

        &::-webkit-slider-thumb {
          -webkit-appearance: none; /* Override default look */
          appearance: none;

          background-color: #ffffff;
          height: 15px;
          width: 15px;
          border-radius: 333px;
        }
      }

      .TimelineBarSection_Section_TimeLine_Line {
        display: flex;
        justify-content: start;
        position: absolute;
        width: 70%;
        height: 5px;
        background-color: #87aec4;
        border-radius: 33px;

        .TimelineBarSection_Section_TimeLine_Line_Progress {
          background-color: $blue_1;
          height: 100%;
          // width: 100%;
          border-radius: 33px;
        }

        .TimelineBarSection_Section_TimeLine_Line_Separators {
          display: flex;
          justify-content: space-between;
          position: absolute;
          width: 100%;
          height: 100%;

          .TimelineBarSection_Section_TimeLine_Line_El {
            border-radius: 33px;
            height: 100%;
            // background-color: #87aec4;
            transition: background 0.5s ease-in-out;

            .TimelineBarSection_Section_TimeLine_Line_El_Shape {
              width: 5px;
              height: 100%;
              background-image: url('../../../../public/img/separator.svg');
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }

    .TimelineBarSection_Section_TimeLine_Time {
      margin-right: 20px;
      display: flex;
      justify-content: center;
      width: 70px;
      display: flex;
      color: #a8bbc6;
      font-size: 16px;

      .TimelineBarSection_Section_TimeLine_Time_Left {
        color: $white;
      }

      .TimelineBarSection_Section_TimeLine_Time_RightColor {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .TimelineBarSection_Section_Volume {
      margin-right: 20px;
      display: flex;
      align-items: center;
      // gap: 10px;
      width: 15%;

      .TimelineBarSection_Section_Volume_Icon {
        margin-right: 10px;
      }

      .TimelineBarSection_Section_Volume_InputWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 95%;

        input {
          -webkit-appearance: none;
          appearance: none;
          background: transparent;
          cursor: pointer;
          width: 100%;
          display: flex;
          align-items: center;

          &::-webkit-slider-runnable-track {
            // background: rgba(255, 255, 255, 0.444);
            height: 5px;
            border-radius: 333px;
          }

          /******** Firefox ********/
          &::-moz-range-track {
            height: 5px;
            border-radius: 333px;
          }

          &::-webkit-slider-thumb {
            -webkit-appearance: none; /* Override default look */
            appearance: none;
            margin-left: 2px;
            background-color: #ffffff;
            height: 15px;
            width: 15px;
            border-radius: 333px;
            margin-top: -5px;
          }
        }

        .TimelineBarSection_Section_Volume_InputWrap_ProgressWrap {
          width: 100%;
          z-index: -10;
          position: absolute;
          border-radius: 333px;
          background-color: rgba(255, 255, 255, 0.444);

          .TimelineBarSection_Section_Volume_InputWrap_Progress {
            transform-origin: center left;
            pointer-events: none;
            border-radius: 333px;
            height: 5px;
            width: 100%;
            background-color: $blue_1;
          }
        }
      }

      .disabled {
        opacity: 0.5;
      }
    }

    .TimelineBarSection_Section_fullScreen {
      display: flex;
      align-items: center;

      .TimelineBarSection_Section_fullScreen_Icon {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .TimelineBarSection {
    position: initial;
    width: 100%;

    .TimelineBarSection_Wrap {
      @include glass_2;
      background: linear-gradient(
        110.28deg,
        rgba(181, 253, 255, 0.1) 0.2%,
        rgba(157, 255, 252, 0.1) 101.11%
      );

      width: 100%;
    }
  }
}

@media screen and (max-width: 620px) {
  .TimelineBarSection {
    position: initial;
    width: 100%;

    .TimelineBarSection_Wrap {
      // gap: 5px;

      .TimelineBarSection_Section_PrevPauseNext {
        // margin-right: 5px;
        @include flexbox-column-gap(5px);

        img {
          scale: 0.6;
        }
      }

      .TimelineBarSection_Section_TimeLine_Time {
        margin-right: 5px;
        width: 60px;
        font-size: 12px;
      }

      .TimelineBarSection_Section_Volume {
        margin-right: 5px;
        width: fit-content;
        input {
          display: none;
        }
      }
    }
  }
}
