@import '../../settings/settings.scss';

.FooterSection {
  display: flex;
  justify-content: center;
  width: 100vw;

  .FooterSection_Wrap {
    margin-top: $header_height;
    display: flex;
    max-width: $max-width;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .FooterSection_Wrap_Top {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;

      .FooterSection_Wrap_Top_Logo {
        // background-image: url('../../../public/img/header_logo.svg');
        width: 282px;
        height: 35px;
      }

      .FooterSection_Wrap_Top_Center {
        display: flex;
        justify-content: center;
        @include flexbox-column-gap(56px);
        color: $g_1;

        .FooterSection_Wrap_Top_Center_El {
          color: $g_1;
          text-decoration: none;
          font-size: 16px;
          font-weight: 500;

          @media (min-width: 1180px) {
            &:hover{
              cursor:pointer;
            }
          }

          @include text_hover;
        }
      }

      .FooterSection_Wrap_Top_Socials {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 40px;
        // gap: 18px;
        @include flexbox-column-gap(18px);

        .FooterSection_Wrap_Top_Socials_El {
          @media (min-width: 1180px) {
            cursor: pointer;
          }
          display: flex;

          width: 45px;
          height: 45px;

          .FooterSection_Wrap_Top_Socials_El_Icon {
            position: absolute;
            transition: opacity 0.3s ease-in-out;
          }

          .FooterSection_Wrap_Top_Socials_El_IconAct {
            position: absolute;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
          }

          @media (min-width: 1180px) {
            &:hover {
              .FooterSection_Wrap_Top_Socials_El_Icon {
                opacity: 0;
              }
  
              .FooterSection_Wrap_Top_Socials_El_IconAct {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .FooterSection_Wrap_Bottom {
      width: 100%;
      margin-top: 83px;
      padding: 50px 0px;
      display: grid;
      align-items: center;
      grid-template-columns: repeat(3, 1fr);
      border-top: 1px #e8e8e8 solid;

      .FooterSection_Wrap_Bottom_left {
        font-size: 16px;
        font-weight: 400;
        color: $g_3;
      }

      .FooterSection_Wrap_Bottom_center {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        color: $g_3;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .FooterSection {
    .FooterSection_Wrap {
      width: calc(100% - $margin_horizontal_1440 * 2);

      .FooterSection_Wrap_Top {
        .FooterSection_Wrap_Top_Logo {
          width: 252px;
          background-size: contain;
          background-repeat: no-repeat;
        }

        .FooterSection_Wrap_Top_Center {
          // gap: 2vw;
          @include flexbox-column-gap(2vw);
        }
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .FooterSection {
    .FooterSection_Wrap {
      margin-top: $header_height * 0.7;
      .FooterSection_Wrap_Top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include flexbox-row-gap(20px);

        .FooterSection_Wrap_Top_Socials {
          width: fit-content;
          margin-top: 10px;
        }
      }

      .FooterSection_Wrap_Bottom {
        display: flex;
        justify-content: start;
        margin-top: $header_height * 0.5;

        .FooterSection_Wrap_Bottom_left {
          display: none;
        }

        .FooterSection_Wrap_Bottom_center {
          text-align: start;
        }
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .FooterSection {
    .FooterSection_Wrap {
      width: calc(100% - $margin_horizontal_920 * 2);

      .FooterSection_Wrap_Bottom {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .FooterSection {
    .FooterSection_Wrap {
      width: calc(100% - $margin_horizontal_480 * 2);
    }
  }
}
